import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
/* eslint-enable no-unused-vars */

import template from './contact-item.html';
import css from './contact-item.css';
const utils = require('../common-utils.js');
/**
 * @polymer
 * @extends HTMLElement
 */
export class ContactItem extends PolymerElement {
  static get properties() {
    return {
      id: {
        type: String,
        value: 'ID missing',
      },
      name: {
        type: String,
        value: 'Name missing',
      },
      ground: {
        type: String,
        value: 'Ground Stations missing',
      },
      equipment: {
        type: String,
        value: 'Equiment String missing',
      },
      state: {
        type: String,
        value: 'State Missing',
      },
      status: {
        type: String,
        computed: 'setStatusIcon(state)',
      },
      stateDetail: {
        type: String,
        value: 'State Detail Missing',
      },
      detail: {
        type: String,
        value: 'No Error Message',
      },
      begin: {
        type: Number,
      },
      end: {
        type: Number,
      },
      expanded: {
        type: Boolean,
        value: false,
      },
      toggleClass: {
        type: String,
        computed: 'computeToggleClass(expanded)',
      },
      commandModes: {
        type: Array,
        value: function() {
          return ['Manual', 'Semi-Automated', 'Fully Automated'];
        },
      },
      mode: {
        type: String,
        value: function() {
          return this.randomlyAssignCommandMode();
        },
      },
    };
  }

  /* Define component-specific methods */

  setStatusIcon() {
    if (this.state == 'executing') return 'normal';
    if (this.state == 'failed') return 'serious';
    return 'off';
  }

  computeToggleClass() {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  toggleExpand() {
    this.dispatchEvent(
        new CustomEvent('contactToggleChanged', {
          detail: {
            contactId: this.id,
            setExpandedTo: !this.expanded,
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  randomlyAssignCommandMode() {
    return this.commandModes[Math.floor(Math.random() * 3)];
  }

  constructor() {
    super();
    /* Register utility methods */
    this.is = utils.is;
    this.capitalize = utils.capitalize;
    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      `<style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('contact-item', ContactItem);
