/* eslint-disable no-unused-vars */
import config from '../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* Astro Components */
import {RuxClock} from '@astrouxds/rux-clock/rux-clock.js';
import {RuxGlobalStatusBar} from '@astrouxds/rux-global-status-bar/rux-global-status-bar.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxTabs} from '@astrouxds/rux-tabs/rux-tabs.js';
// import { RuxButton } from "@astrouxds/rux-button/rux-button.js";
// import { RuxNotification } from "@astrouxds/rux-notification/rux-notification.js";
// import { RuxModal } from "@astrouxds/rux-modal/rux-modal.js";
// import { RuxPopUpMenu } from "@astrouxds/rux-pop-up-menu/rux-pop-up-menu.js";
// import { RuxPushButton } from "@astrouxds/rux-push-button/rux-push-button.js";
// import { RuxProgress } from "@astrouxds/rux-progress/rux-progress.js";
// import { RuxSegmentedButton } from "@astrouxds/rux-segmented-button/rux-segmented-button.js";
// import { RuxSlider } from "@astrouxds/rux-slider/rux-slider.js";
// import { RuxSpectrumAnalyzer } from "@astrouxds/rux-spectrum-analyzer/rux-spectrum-analyzer.js";
// import { RuxTimeline } from "@astrouxds/rux-timeline/rux-timeline.js";
// import { RuxToggle } from "@astrouxds/rux-toggle/rux-toggle.js";
// import { RuxTree } from "@astrouxds/rux-tree/rux-tree.js";

/* GRM Services Components */
import {GrmAlerts} from './alerts/alerts.js';
import {AppMenu} from './app-menu.js';
import {CurrentContacts} from './contacts/current-contacts.js';
import {StatusIndicators} from './status/status-indicators';
import {GrmEquipmentTrending} from './equipment/equipment-trending.js';
import {GrmEquipmentStatus} from './equipment/equipment-status.js';
import {GrmContactsSummary} from './contacts/contacts-summary.js';

/* GRM Services Templates and Styles */
import template from './astro-app.html';
import css from './astro-app.css';
import astroCSS from './astro-css.js';
const utils = require('./common-utils.js');
/* eslint-enable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class AstroApp extends PolymerElement {
  static get properties() {
    return {
      alertsLog: {
        type: Array,
        value: [
          {
            errorId: '6d76630e-e99f-5615-9bd8-331a0fc4b955',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 121 - Offline',
            longMessage: 'Red FEP 121 is offline at 18:37:45',
            errorTime: 1542134265725,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '20a96646-abbc-5195-9b20-cff2e99f2ada',
            errorSeverity: 'critical',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-168 - Power degradation',
            longMessage: 'USA-168 suffered power degradation at 18:37:54',
            errorTime: 1542134274738,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'e7d304c2-17ef-5426-ac70-4431fa580409',
            errorSeverity: 'critical',
            errorCategory: 'software',
            errorMessage: 'Black FEP 121 - Degraded',
            longMessage: 'Black FEP 121 is degraded at 18:37:57',
            errorTime: 1542134277742,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '47e8c77b-227e-5606-8718-7fafb67f8f8b',
            errorSeverity: 'caution',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-150 - Solar panel misalignment',
            longMessage: 'USA-150 experienced solar panel misalignment at 18:38:00',
            errorTime: 1542134280747,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '55282589-4b4b-5ac2-b6c9-97b0bf06dfad',
            errorSeverity: 'critical',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 1 - Offline',
            longMessage: 'Antenna DGS 1 went offline at 18:38:09',
            errorTime: 1542134289757,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'f35ff6e5-ea12-572b-8a27-ecae8b716ae2',
            errorSeverity: 'serious',
            errorCategory: 'software',
            errorMessage: 'Red FEP 201 - Degraded',
            longMessage: 'Red FEP 201 is degraded at 18:38:16',
            errorTime: 1542134296767,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '5ca28dca-fbd2-5328-9123-7dfa1a5f0ad9',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 301 - Degraded',
            longMessage: 'Red FEP 301 is degraded at 18:38:17',
            errorTime: 1542134297768,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '576d94af-206a-5281-a86a-027ee8d13ec3',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 2 - Offline',
            longMessage: 'Antenna DGS 2 went offline at 18:38:23',
            errorTime: 1542134303776,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'de220699-9b65-5eea-ab06-a1eeb5bd5e23',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Workstation 134 - Offline',
            longMessage: 'Workstation 134 is offline at 18:38:24',
            errorTime: 1542134304777,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'c9c1060c-03f5-5669-b0d4-c2f89ba9d0e1',
            errorSeverity: 'critical',
            errorCategory: 'software',
            errorMessage: 'Black FEP 121 - Offline',
            longMessage: 'Black FEP 121 is offline at 18:38:27',
            errorTime: 1542134307782,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '53334558-f2ff-5def-89a4-2e1aacda6e3d',
            errorSeverity: 'critical',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-177 - Solar panel misalignment',
            longMessage: 'USA-177 experienced solar panel misalignment at 18:38:32',
            errorTime: 1542134312791,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '9615b023-e556-592f-8358-01775dd125f7',
            errorSeverity: 'critical',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 2 - Weak signal',
            longMessage: 'Antenna DGS 2 has weak signal at 18:38:37',
            errorTime: 1542134317799,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'd0db6011-c352-5829-96ac-c00dd743fc95',
            errorSeverity: 'critical',
            errorCategory: 'hardware',
            errorMessage: 'Antenna HTS 1 - NOLOCK',
            longMessage: 'Antenna HTS 1 received NOLOCK at 18:38:38',
            errorTime: 1542134318801,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '74321a09-935b-52c5-9b45-3f29e9a75062',
            errorSeverity: 'caution',
            errorCategory: 'hardware',
            errorMessage: 'Antenna VTS 2 - Weak signal',
            longMessage: 'Antenna VTS 2 has weak signal at 18:38:43',
            errorTime: 1542134323807,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '9d263679-b34d-5ce6-ba0c-88e436774e2c',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Workstation 134 - Out of disk space',
            longMessage: 'Workstation 134 is out of disk space at 18:38:44',
            errorTime: 1542134324809,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '35836dba-90da-54e3-914c-f40fa05ccfd4',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 2 - NOLOCK',
            longMessage: 'Antenna DGS 2 received NOLOCK at 18:38:51',
            errorTime: 1542134331818,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '11f05812-a98c-5488-b50f-fd8716514b1e',
            errorSeverity: 'serious',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-145 - SARM failure',
            longMessage: 'USA-145 experienced SARM failure at 18:39:02',
            errorTime: 1542134342832,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'f97eb85a-34c2-5f64-9e1c-7ae9bbdb50fa',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Workstation 202 - Out of disk space',
            longMessage: 'Workstation 202 is out of disk space at 18:39:05',
            errorTime: 1542134345836,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '8c6b253b-fd7e-599b-8682-00f87ac52b25',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Workstation 101 - Memory limit reached',
            longMessage: 'Workstation 101 has reached memory limit at 18:39:12',
            errorTime: 1542134352844,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '87035c8d-e5a5-5600-893b-ad243f568054',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 121 - Offline',
            longMessage: 'Red FEP 121 is offline at 18:39:15',
            errorTime: 1542134355848,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '8b141bb5-3ee9-5174-bd2b-d11421d74a03',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Antenna HTS 2 - Weak signal',
            longMessage: 'Antenna HTS 2 has weak signal at 18:39:18',
            errorTime: 1542134358852,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'c5b35d9c-81b4-5ea8-9ab4-87f71642591d',
            errorSeverity: 'caution',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 2 - Offline',
            longMessage: 'Antenna DGS 2 went offline at 18:39:23',
            errorTime: 1542134363860,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'a2d52c48-2e7a-56e7-8032-6a3c21474f9d',
            errorSeverity: 'critical',
            errorCategory: 'software',
            errorMessage: 'Black FEP 301 - Offline',
            longMessage: 'Black FEP 301 is offline at 18:39:28',
            errorTime: 1542134368866,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '0031ccef-c262-5dbe-8e8e-e22229b529e3',
            errorSeverity: 'critical',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-180 - Power degradation',
            longMessage: 'USA-180 suffered power degradation at 18:39:34',
            errorTime: 1542134374882,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '70f51db6-039d-5794-9c13-8c28d08daea1',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 201 - Offline',
            longMessage: 'Red FEP 201 is offline at 18:39:42',
            errorTime: 1542134382891,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '81a6d9cd-86dc-5506-b0b5-75173af7a07f',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Antenna HTS 2 - Offline',
            longMessage: 'Antenna HTS 2 went offline at 18:39:54',
            errorTime: 1542134394907,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '730e692e-2698-5a8b-8006-88aa1de4a1cb',
            errorSeverity: 'serious',
            errorCategory: 'software',
            errorMessage: 'Red FEP 301 - Offline',
            longMessage: 'Red FEP 301 is offline at 18:40:12',
            errorTime: 1542134412929,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '28f8ca0d-0e90-59fa-a482-b6fa4449a704',
            errorSeverity: 'serious',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-177 - SARM failure',
            longMessage: 'USA-177 experienced SARM failure at 18:40:15',
            errorTime: 1542134415933,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '39179992-b023-599d-8589-b2d4877a7c53',
            errorSeverity: 'serious',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-145 - Solar panel misalignment',
            longMessage: 'USA-145 experienced solar panel misalignment at 18:40:17',
            errorTime: 1542134417934,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '2eb45565-2c1a-5876-9894-23f7997040ec',
            errorSeverity: 'serious',
            errorCategory: 'software',
            errorMessage: 'Black FEP 124 - Degraded',
            longMessage: 'Black FEP 124 is degraded at 18:40:21',
            errorTime: 1542134421939,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'd3472160-9d02-5f20-95ee-772c02a6233a',
            errorSeverity: 'critical',
            errorCategory: 'software',
            errorMessage: 'Red FEP 124 - Offline',
            longMessage: 'Red FEP 124 is offline at 18:40:27',
            errorTime: 1542134427946,
            selected: false,
            new: false,
            expanded: false,
          },
        ],
      },
      contactsLog: {
        type: Array,
        value: [
          {
            contactId: '80e5654b-df70-5a9c-85dd-75541a7cafae',
            contactName: '22683',
            contactGround: 'HTS',
            contactEquipment: 'ANT43 VAFB1 SFEP227CH1 ECEU6 WS402 USP177',
            contactState: 'executing',
            contactStep: 'Downlink',
            contactDetail:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            contactBeginTimestamp: 1541086435343,
            contactEndTimestamp: 1541088368326,
            expanded: false,
          },
          {
            contactId: '40e5654b-df70-5a9c-85dd-75541a7cafae',
            contactName: '68112',
            contactGround: 'VTS',
            contactEquipment: 'ANT74 BAFB1 SFEP299CH1 ECEU6 WS305 USP451',
            contactState: 'failed',
            contactStep: 'AOS',
            contactDetail:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            contactBeginTimestamp: 1541088235343,
            contactEndTimestamp: 1541088398326,
            expanded: false,
          },
          {
            contactId: '70e5654b-df70-5a9c-85dd-75541a7cafae',
            contactName: '16834',
            contactGround: 'DGS',
            contactEquipment: 'ANT73 PAFB1 SFEP149CH1 ECEU6 WS167 USP182',
            contactState: 'executing',
            contactStep: 'Uplink',
            contactDetail:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            contactBeginTimestamp: 1541088635343,
            contactEndTimestamp: 1541088768326,
            expanded: false,
          },
          {
            contactId: '00e5654b-df70-5a9c-85dd-75541a7cafae',
            contactName: '62346',
            contactGround: 'TCS',
            contactEquipment: 'ANT52 SAFB1 SFEP374CH1 ECEU6 WS481 USP342',
            contactState: 'executing',
            contactStep: 'Downlink',
            contactDetail:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            contactBeginTimestamp: 1541085435343,
            contactEndTimestamp: 1541089368326,
            expanded: false,
          },
        ],
      },
      statusData: {
        type: Object,
        value: {
          commsStatus: {worstStatus: 'standby', numMessages: 22},
          digitalStatus: {worstStatus: 'caution', numMessages: 35},
          facilitiesStatus: {worstStatus: 'standby', numMessages: 28},
          rfStatus: {worstStatus: 'off', numMessages: 27},
          softwareStatus: {worstStatus: 'caution', numMessages: 2},
          timestamp: 1546641719633,
        },
      },
      ucaProgress: {
        type: Number,
        value: 2,
      },
    };
  }

  _startAlertsWebsocket() {
    const ws = new WebSocket(config.dataServers.alerts);

    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      payload.selected = false;
      payload.expanded = false;
      payload.new = true;
      const index = this.alertsLog.length;
      this.push('alertsLog', payload);

      setTimeout(() => {
        this.set(['alertsLog', index, 'new'], false);
      }, 2000);
    });

    /* Event Listeners for Alerts */
    this.addEventListener('removeAlerts', this._removeItemsFromAlertsLog);
    this.addEventListener('alertSelectionChanged', this._setAlertSelectedState);
    this.addEventListener('alertToggleChanged', this._setAlertExpandedState);
    this.addEventListener('contactToggleChanged', this._setContactExpandedState);
  }

  _getCurrentContacts() {
    fetch(config.dataServers.contacts)
        .then((response) => {
          return response.json();
        })
        .then((theJson) => {
          for (const contact of theJson) {
            this.push('contactsLog', contact);
          }
        });
  }

  _startStatusIndicatorsWebsocket() {
    const ws = new WebSocket(config.dataServers.statusIndicators);
    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.statusData = payload;
    });
  }

  _startUCAProgressIndicator() {
    setInterval(() => {
      let _progress = this.ucaProgress;
      const _increment = Math.floor(Math.random() * 5);

      if (_progress + _increment > 100) {
        _progress = _increment;
      } else {
        _progress += _increment;
      }

      this.set('ucaProgress', _progress);
    }, 1000);
  }

  isAlertVisible(status, category, alert) {
    if (status !== 'all' && status !== alert.errorSeverity) return false;
    if (category !== 'all' && category !== alert.errorCategory) return false;
    return true;
  }

  _removeItemsFromAlertsLog(event) {
    const alertsToKeep = this.alertsLog.filter((alert) => {
      const isVisible = this.isAlertVisible(event.detail.activeStatusFilter, event.detail.activeCategoryFilter, alert);
      // you can stay in the log if you are invisible or not selected
      return !isVisible || !alert.selected;
    });

    this.set('alertsLog', alertsToKeep);
  }

  _setAlertSelectedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'selected'], event.detail.setSelectedTo);
        this.notifyPath(['alertsLog', index, 'selected']);
      }
    });
  }

  _setAlertExpandedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'expanded'], event.detail.setExpandedTo);
        this.notifyPath(['alertsLog', index, 'expanded']);
      }
    });
  }
  _setContactExpandedState(event) {
    this.contactsLog.forEach((contact, index) => {
      if (contact.contactId === event.detail.contactId) {
        this.set(['contactsLog', index, 'expanded'], event.detail.setExpandedTo);
        this.notifyPath(['contactsLog', index, 'expanded']);
      }
    });
  }

  displayPopUpMenu(e) {
    if (e.detail.menu) {
      this.appMenuTarget = e.detail.target;
      this.menuItems = e.detail.menu;
    } else {
      this.appMenuTarget = e.currentTarget;
      this.menuItems = config.menuItems;
    }
  }

  constructor() {
    super();
    this.menuItems = config.menuItems;
    this.user = config.user;

    this.addEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  connectedCallback() {
    super.connectedCallback();
    this._startAlertsWebsocket();
    this._getCurrentContacts();
    this._startStatusIndicatorsWebsocket();
    this._startUCAProgressIndicator();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
        ${css}
        </style> 
        ${template}`,
    ]);
  }
}
customElements.define('astro-app', AstroApp);
