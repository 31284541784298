import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import * as d3 from 'd3';

import template from './equipment-trending.html';
import css from './equipment-trending.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class GrmEquipmentTrending extends PolymerElement {
  static get properties() {
    return {
      potentialEquipmentStates: {
        type: Array,
        value: [
          {
            stateName: 'Busy',
          },
          {
            stateName: 'Idle',
          },
          {
            stateName: 'Inoperable' + '\xa0\xa0\xa0\xa0\xa0',
          },
        ],
      },
      stateFilter: {
        type: String,
        value: 'Busy',
      },
      selected: {
        type: Boolean,
        value: true,
      },
    };
  }

  static get template() {
    return html([
      `
        <style include='astro-css'>
        ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this._buildLineChart();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  is(a, b) {
    return a === b;
  }

  _buildLineChart() {
    // Set the maximum random value - make them differ a bit for a more real-world visualization
    const maxRandomRFValue = 100;
    const maxRandomDigitalValue = 85;
    const maxRandomCommsValue = 75;
    const maxRandomFacilitiesValue = 65;

    // Before or after current time - need to let the getHour function know whether to add or subtract
    const beforeCurrentHour = true;

    // Set the threshold for the Y Axis grid
    const threshold = 90;

    // Set the opacity for the Usage Threshold line and text
    let thresholdOpacity = .8;

    // Data for the line chart. Each state (Busy/Idle/Inoperable) have thier own set of data
    // The line chart displays 4 hours in the future/current hour/8 hours in the past
    const dataSetBusy = [
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 240),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 180),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 120),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 60),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 0),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 60),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 120),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 180),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 240),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 300),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 360),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 420),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 480),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 540),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
    ];

    const dataSetIdle = [
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 240),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 180),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 120),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 60),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 0),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 60),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 120),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 180),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 240),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 300),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 360),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 420),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 480),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 540),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
    ];

    const dataSetInoperable = [
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 240),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 180),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 120),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 60),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(!beforeCurrentHour, 0),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 60),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 120),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 180),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 240),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 300),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 360),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 420),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 480),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 540),
        'rf': generateRandomNumber(maxRandomRFValue),
        'digital': generateRandomNumber(maxRandomDigitalValue),
        'comms': generateRandomNumber(maxRandomCommsValue),
        'facilities': generateRandomNumber(maxRandomFacilitiesValue),
      },
    ];

    // Handle for the drop down
    const stateFilter = this.shadowRoot.querySelector('#stateFilter');

    // Handles to the check boxes
    const rfChanged = this.shadowRoot.querySelector('#checkboxRF');
    const digitalChanged = this.shadowRoot.querySelector('#checkboxDigital');
    const commsChanged = this.shadowRoot.querySelector('#checkboxComms');
    const facilitiesChanged = this.shadowRoot.querySelector('#checkboxFacilities');

    const margin = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 60,
    };

    // Width and height of the line chart
    const width = 1400;
    const height = 200;

    // Set the ranges
    const x = d3.scaleTime().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    // Format the Y axis labels with %
    const yAxis = d3
        .axisLeft(y)
        .tickFormat(function(d) {
          return d + '%';
        })
        .ticks(10);

    // Gridlines in y axis function
    function makeYGridlines() {
      return d3.axisLeft(y).ticks(10);
    }

    // Define the RF line
    const rfLine = d3
        .line()
        .x(function(d) {
          return x(d.time);
        })
        .y(function(d) {
          return y(d.rf);
        });

    // Define the Digital line
    const digitalLine = d3
        .line()
        .x(function(d) {
          return x(d.time);
        })
        .y(function(d) {
          return y(d.digital);
        });

    // Define the Comms line
    const commsLine = d3
        .line()
        .x(function(d) {
          return x(d.time);
        })
        .y(function(d) {
          return y(d.comms);
        });

    // Define the Facilities line
    const facilitiesLine = d3
        .line()
        .x(function(d) {
          return x(d.time);
        })
        .y(function(d) {
          return y(d.facilities);
        });

    const svg = d3.select(this.shadowRoot.querySelector('#lineChart')).append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 1500 400')
        .append('g')
        .attr('transform',
            'translate(' + margin.left + ',' + margin.top + ')');

    // Get and format the data
    dataSetBusy.forEach(function(d) {
      d.rf_busy = +d.rf_busy;
      d.digital_busy = +d.digital_busy;
      d.comms_busy = +d.comms_busy;
      d.facilities_busy = +d.facilities_busy;
    });

    // Scale the range of the data
    x.domain(
        d3.extent(dataSetBusy, function(d) {
          return d.time;
        })
    );
    y.domain([
      0,
      d3.max(dataSetBusy, function() {
        return Math.max(0, 100);
      }),
    ]);

    // Add the rfLine path.
    svg
        .append('path')
        .data([dataSetBusy])
        .attr('class', 'rf_line')
        .attr('id', 'rf_line')
        .style('stroke', '#4DACFF')
        .attr('d', rfLine);

    // Add the digitalLine path.
    svg
        .append('path')
        .data([dataSetBusy])
        .attr('class', 'digital_line ')
        .attr('id', 'digital_line ')
        .style('stroke', '#4DACFF')
        .attr('d', digitalLine);

    // Add the commsLine path.
    svg
        .append('path')
        .data([dataSetBusy])
        .style('stroke', '#FFFFFF')
        .attr('class', 'comms_line')
        .attr('id', 'comms_line')
        .attr('d', commsLine);

    // Add the facilitiesLine path.
    svg
        .append('path')
        .data([dataSetBusy])
        .attr('class', 'facilities_line')
        .attr('id', 'facilities_line')
        .style('stroke', '#FFFFFF')
        .attr('d', facilitiesLine);

    // Add the X Axis
    svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'axis')
        .call(d3.axisBottom(x)
            .tickFormat(d3.timeFormat('%H' + '00'))); // Format the time label

    // Add the Y Axis
    svg
        .append('g')
        .attr('class', 'axis')
        .call(yAxis);

    // Add the Y gridlines
    svg
        .append('g')
        .attr('class', 'grid')
        .attr('opacity', 0.5)
        .call(
            makeYGridlines()
                .tickSize(-width)
                .tickFormat('')
        );

    // Threshold line and text group
    const thresholdText = svg.append('g')
        .attr('class', 'threshold-text');

    // Threshold text
    thresholdText.append('text')
        .attr('class', 'threshold-text')
        .attr('y', y(threshold))
        .attr('dy', '12px')
        .attr('x', '650px')
        .attr('opacity', thresholdOpacity)
        .text('Usage Threshold');

    // Threshold bold line - dashed
    thresholdText.append('line')
        .attr('class', 'threshold-line')
        .attr('x1', 0)
        .attr('y1', y(threshold))
        .attr('x2', width)
        .attr('stroke-opacity', thresholdOpacity)
        .attr('y2', y(threshold));

    const chartUpdate = this._getChart();

    // Event handler for drop down
    stateFilter.addEventListener('change', function(e) {
      update(e.target.value);
    });

    // Event handlers for the checkboxes
    rfChanged.addEventListener('change', function(e) {
      const svgRF = d3.select(chartUpdate).transition();
      if (e.target.checked) {
        // Make the changes
        svgRF
            .select('.rf_line') // change the line
            .duration(500)
            .attr('opacity', 1);
      } else {
        // Make the changes
        svgRF
            .select('.rf_line') // change the line
            .duration(500)
            .attr('opacity', 0);
      }
    });

    digitalChanged.addEventListener('change', function(e) {
      const svgDigital = d3.select(chartUpdate).transition();
      if (e.target.checked) {
        // Make the changes
        svgDigital
            .select('.digital_line') // change the line
            .duration(500)
            .attr('opacity', 1);
      } else {
        // Make the changes
        svgDigital
            .select('.digital_line') // change the line
            .duration(500)
            .attr('opacity', 0);
      }
    });

    commsChanged.addEventListener('change', function(e) {
      const svgComms = d3.select(chartUpdate).transition();
      if (e.target.checked) {
        // Make the changes
        svgComms
            .select('.comms_line') // change the line
            .duration(500)
            .attr('opacity', 1);
      } else {
        // Make the changes
        svgComms
            .select('.comms_line') // change the line
            .duration(500)
            .attr('opacity', 0);
      }
    });

    facilitiesChanged.addEventListener('change', function(e) {
      const svgFacilities = d3.select(chartUpdate).transition();
      if (e.target.checked) {
        // Make the changes
        svgFacilities
            .select('.facilities_line') // change the line
            .duration(500)
            .attr('opacity', 1);
      } else {
        // Make the changes
        svgFacilities
            .select('.facilities_line') // change the line
            .duration(500)
            .attr('opacity', 0);
      }
    });

    // This function updates the line chart when the dropdown is changed
    function update(target) {
      let dataSetTemp = [];
      switch (target.trim()) {
        case 'Busy':
          dataSetTemp = dataSetBusy;
          thresholdOpacity = .8;
          break;
        case 'Idle':
          dataSetTemp = dataSetIdle;
          thresholdOpacity = 0;
          break;
        case 'Inoperable':
          dataSetTemp = dataSetInoperable;
          thresholdOpacity = 0;
          break;
        default:
          dataSetTemp = dataSetBusy;
          thresholdOpacity = .8;
          break;
      }

      // Scale the range of the data again
      x.domain(
          d3.extent(dataSetTemp, function(d) {
            return d.time;
          })
      );
      y.domain([
        0,
        d3.max(dataSetTemp, function() {
          return Math.max(0, 100);
        }),
      ]);

      // Select the section we want to apply our changes to
      const svgNew = d3.select(chartUpdate).transition();

      // Make the changes
      svgNew
          .select('.rf_line') // change the line
          .duration(750)
          .attr('d', rfLine(dataSetTemp));

      svgNew
          .select('.digital_line') // change the line
          .duration(750)
          .attr('d', digitalLine(dataSetTemp));

      svgNew
          .select('.comms_line') // change the line
          .duration(750)
          .attr('d', commsLine(dataSetTemp));

      svgNew
          .select('.facilities_line') // change the line
          .duration(750)
          .attr('d', facilitiesLine(dataSetTemp));

      svgNew
          .select('.x.axis') // change the x axis
          .duration(750)
          .call(x);

      svgNew
          .select('.y.axis') // change the y axis
          .duration(750)
          .call(y);

      svgNew
          .select('.threshold-text')
          .duration(750)
          .attr('opacity', thresholdOpacity);

      svgNew
          .select('.threshold-line')
          .duration(750)
          .attr('stroke-opacity', thresholdOpacity);
    }
  }

  _getChart() {
    return this.shadowRoot.querySelector('#lineChart');
  }
}

customElements.define('grm-equipment-trending', GrmEquipmentTrending);

// Generate a random number between 1 - max
function generateRandomNumber(max) {
  return Math.floor(Math.random() * max) + 1;
}

// This will return a previous hour, current hour, or future hour
function getHour(beforeCurrentHour, hour) {
  // Get current date
  const currentTimeDate = new Date();
  const offset = 1000 * 60 * currentTimeDate.getTimezoneOffset();

  if (beforeCurrentHour) {
    return currentTimeDate.getTime() - hour + offset;
  } else {
    return currentTimeDate.getTime() + hour + offset;
  }
}

