import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import template from './contacts-summary.html';
import css from './contacts-summary.css';
import * as d3 from 'd3';

/**
 * @polymer
 * @extends HTMLElement
 */
export class GrmContactsSummary extends PolymerElement {
  static get properties() {
    return {};
  }

  static get template() {
    return html([
      `
        <style include='astro-css'>
        ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this._buildBarChart();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  _buildBarChart() {
    // Before or after current time - need to let the getHour function know whether to add or subtract
    const beforeCurrentHour = true;

    const barChartData = [
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 420),
        'issues': 8,
        'progress': 9,
        'planned': 9,
        'completed': 6,
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 360),
        'issues': 8,
        'progress': 7,
        'planned': 8,
        'completed': 8,
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 300),
        'issues': 6,
        'progress': 0,
        'planned': 14,
        'completed': 15,
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 240),
        'issues': 7,
        'progress': 9,
        'planned': 6,
        'completed': 5,
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 180),
        'issues': 9,
        'progress': 9,
        'planned': 10,
        'completed': 9,
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 120),
        'issues': 7,
        'progress': 9,
        'planned': 8,
        'completed': 8,
      },
      {
        'time': getHour(beforeCurrentHour, 1000 * 60 * 60),
        'issues': 7,
        'progress': 8,
        'planned': 6,
        'completed': 4,
      },
      {
        'time': getHour(!beforeCurrentHour, 0),
        'issues': 5,
        'progress': 8,
        'planned': 7,
        'completed': 9,
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 60),
        'issues': 5,
        'progress': 7,
        'planned': 8,
        'completed': 7,
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 120),
        'issues': 6,
        'progress': 9,
        'planned': 4,
        'completed': 6,
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 180),
        'issues': 15,
        'progress': 0,
        'planned': 5,
        'completed': 6,
      },
      {
        'time': getHour(!beforeCurrentHour, 1000 * 60 * 240),
        'issues': 5,
        'progress': 0,
        'planned': 18,
        'completed': 9,
      },
    ];

    const barChartValues = [5, 6, 5, 5, 7, 7, 9, 7,
      8, 8, 6, 7, 9, 7, 7, 5, 5, 6, 15, 5,
      9, 7, 0, 9, 9, 9, 8, 8, 7, 9, 0, 0,
      9, 8, 14, 6, 10, 8, 6, 7, 8, 4, 5, 18,
      6, 8, 15, 5, 9, 8, 4, 9, 7, 6, 6, 9];

    // Keep the values of the X and Y elements so that we can properly place the text within each rect.
    const yStore = [];
    let yStoreCount = 0;
    const xValue = [];
    let xValueCount = 0;

    const series = d3.stack()
        .keys(['issues', 'progress', 'planned', 'completed'])
        .offset(d3.stackOffsetDiverging)(barChartData);

    // set the dimensions and margins of the graph
    const margin = {
      top: 5,
      right: 20,
      bottom: 15,
      left: 40,
    };

    const width = 1400 - margin.left - margin.right;
    const height = 260 - margin.top - margin.bottom;

    const svg = d3.select(this.shadowRoot.querySelector('#chart')).append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 1400 260')
        .append('g')
        .attr('transform',
            'translate(' + margin.left + ',' + margin.top + ')');

    const x = d3.scaleBand()
        .domain(barChartData.map((d) => d.time))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.5);

    const y = d3.scaleLinear()
        .domain([0, d3.max(series, (serie) => d3.max(serie, (d) => d[1]))])
        .rangeRound([height - margin.bottom, margin.top]);

    const z = d3.scaleOrdinal(['#0C6364', '#660000', '#3B3767', '#4D5C6B']);

    // gridlines in y axis function
    function makeYGridliness() {
      return d3.axisLeft(y)
          .ticks(10);
    }

    // add the Y gridlines
    svg.append('g')
        .attr('class', 'grid')
        .attr('opacity', .5)
        .call(makeYGridliness()
            .tickSize(-width)
            .tickFormat('')
        );

    // Build the rectangle that outlines the time in the past
    svg.append('rect')
        .attr('x', 0)
        .attr('y', 17)
        .attr('width', 810)
        .attr('height', height - 32)
        .attr('fill', '#2E6799')
        .attr('opacity', .3);

    svg.append('g')
        .selectAll('g')
        .data(series)
        .enter().append('g')
        .attr('fill', function(d) {
          return z(d.key);
        })
        .selectAll('rect')
        .data(function(d) {
          return d;
        })
        .enter().append('rect')
        .attr('width', 60)
        .attr('x', function(d) {
          xValue[xValueCount++] = x(d.data.time);
          return x(d.data.time);
        })
        .attr('y', function(d) {
          yStore[yStoreCount] = y(d[1]);
          yStoreCount++;
          return y(d[1]);
        })
        .attr('height', function(d) {
          return y(d[0]) - y(d[1]);
        });

    svg.selectAll('text')
        .data(barChartValues)
        .enter()
        .append('text')
        .text(function(d) {
          if (d === 0) {
            return '';
          } else {
            return `${d}`;
          }
        })
        .attr('x', function(d, i) {
          i-=8;
          return xValue[i] + 25;
        })
        .attr('y', function(d, i) {
          i-=8;
          if (i >= 0 && i <= 11) {
            return yStore[i] + 15;
          } else if (i > 11 && i <= 23) {
            return yStore[i] + 15;
          } else if (i > 23 && i <= 35) {
            return yStore[i] + 15;
          } else {
            return yStore[i] + 15;
          }
        })
        .attr('font-family', 'Open Sans')
        .attr('font-size', '12px')
        .attr('fill', 'white')
        .attr('opacity', '.85')
        .attr('font-weight', 'bold');

    svg.append('g')
        .attr('transform', 'translate(0,' + y(0) + ')')
        .attr('class', 'axisWhite')
        .call(d3.axisBottom(x)
            .tickFormat(d3.timeFormat('%H' + '00'))); // Format the time label

    svg.append('g')
        .attr('transform', 'translate(' + 0 + ',0)')
        .call(d3.axisLeft(y))
        .attr('class', 'axisWhite');
  }
}

customElements.define('grm-contacts-summary', GrmContactsSummary);

// This will return a previous hour, current hour, or future hour
function getHour(beforeCurrentHour, hour) {
  // Get current date
  const currentTimeDate = new Date();
  const offset = 1000 * 60 * currentTimeDate.getTimezoneOffset();

  if (beforeCurrentHour) {
    return currentTimeDate.getTime() - hour + offset;
  } else {
    return currentTimeDate.getTime() + hour + offset;
  }
}
