import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {ContactItem} from './contact-item.js';
/* eslint-enable no-unused-vars */

import template from './current-contacts.html';
import css from './current-contacts.css';
const utils = require('../common-utils.js');

/**
 * @polymer
 * @extends HTMLElement
 */
export class CurrentContacts extends PolymerElement {
  static get properties() {
    return {
      potentialStates: {
        type: Array,
        value: [
          {stateName: 'all'},
          {stateName: 'executing'},
          {stateName: 'failed'},
        ],
      },
      renderedItemCount: {
        type: Number,
      },
      numFailed: {
        type: Number,
        computed: 'calculateNumFailed(log.*)',
      },
      numExecuting: {
        type: Number,
        computed: 'calculateNumExecuting(log.*)',
      },
      log: {
        type: Array,
      },
      stateFilter: {
        type: String,
        value: 'all',
      },
      noFiltersSet: {
        type: Boolean,
        computed: 'areFiltersSet(stateFilter)',
      },
    };
  }

  /* Define component-specific methods */

  sortcontactsByStartTime(a, b) {
    return a.contactBeginTimestamp > b.contactBeginTimestamp ? 1 : -1;
  }

  applyFilters(state) {
    return (contact) => {
      if (state === 'all') return true;
      if (state !== contact.contactState) return false;
      return true;
    };
  }

  calculateNumExecuting() {
    const executing = this.log.filter((item) => {
      return item.contactState === 'executing';
    });
    return executing.length;
  }
  calculateNumFailed() {
    const failed = this.log.filter((item) => {
      return item.contactState === 'failed';
    });
    return failed.length;
  }

  areFiltersSet() {
    if (this.stateFilter !== 'all') return false;
    return true;
  }

  clearFilters() {
    this.stateFilter = 'all';
  }


  constructor() {
    super();
    /* Register utility methods */
    this.capitalize = utils.capitalize;
    this.is = utils.is;
    this.isPlural = utils.isPlural;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('current-contacts', CurrentContacts);
