import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {GrmAlert} from './alert.js';
/* eslint-enable no-unused-vars */

import template from './alerts.html';
import css from './alerts.css';
const utils = require('../common-utils.js');

/**
 * @polymer
 * @extends HTMLElement
 */
export class GrmAlerts extends PolymerElement {
  static get properties() {
    return {
      potentialStatuses: {
        type: Array,
        value: [
          {statusName: 'all'},
          {statusName: 'critical'},
          {statusName: 'serious'},
          {statusName: 'caution'},
        ],
      },
      potentialCategories: {
        type: Array,
        value: [
          {categoryName: 'all'},
          {categoryName: 'hardware'},
          {categoryName: 'software'},
          {categoryName: 'spacecraft'},
        ],
      },
      renderedItemCount: {
        type: Number,
      },
      log: {
        type: Array,
      },
      statusFilter: {
        type: String,
        value: 'all',
      },
      categoryFilter: {
        type: String,
        value: 'all',
      },
      selectAllState: {
        type: Boolean,
        value: false,
      },
      selectAllLabel: {
        type: String,
        computed: 'defineSelectAllLabel(selectAllState)',
      },
      anyAlertSelected: {
        type: Boolean,
        computed: 'isSelectionActive(log.*)',
        value: false,
      },
      noFiltersSet: {
        type: Boolean,
        computed: 'areFiltersSet(categoryFilter, statusFilter)',
      },
    };
  }

  /* Define component-specific methods */

  sortAlertsByTimestamp(a, b) {
    return a.errorTime > b.errorTime ? -1 : 1;
  }

  defineSelectAllLabel() {
    return this.selectAllState ? 'Select None' : 'Select All';
  }

  isSelectionActive() {
    return this.log.some((alert) => alert.selected);
  }

  selectAll() {
    if (this.log.length < 1 ) {
      return;
    }
    const previousSelectAll = this.selectAllState;
    this.log.forEach((alert) => {
      if (!this.isAlertVisible(this.statusFilter, this.categoryFilter, alert)) {
        return;
      }

      this.dispatchEvent(
          new CustomEvent('alertSelectionChanged', {
            detail: {
              errorId: alert.errorId,
              setSelectedTo: !previousSelectAll,
            },
            bubbles: true,
            composed: true,
          })
      );
    });
    this.selectAllState = !this.selectAllState;
  }

  removeSelectedAlersFromLog() {
    this.dispatchEvent(
        new CustomEvent('removeAlerts', {
          detail: {
            activeStatusFilter: this.statusFilter,
            activeCategoryFilter: this.categoryFilter,
          },
          bubbles: true,
          composed: true,
        })
    );

    if (!this.anyAlertSelected) {
      this.selectAllState = false;
    }
  }

  applyFilters(status, category) {
    return (alert) => {
      if (status === 'all' && category === 'all') return true;
      return this.isAlertVisible(status, category, alert);
    };
  }

  isAlertVisible(status, category, alert) {
    if (status !== 'all' && status !== alert.errorSeverity) return false;
    if (category !== 'all' && category !== alert.errorCategory) return false;
    return true;
  }

  areFiltersSet() {
    if (this.categoryFilter !== 'all' || this.statusFilter !== 'all') {
      return false;
    }
    return true;
  }

  clearFilters() {
    this.statusFilter = 'all';
    this.categoryFilter = 'all';
  }

  constructor() {
    super();
    /* Register utility methods */
    this.capitalize = utils.capitalize;
    this.is = utils.is;
    this.isPlural = utils.isPlural;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('grm-alerts', GrmAlerts);
